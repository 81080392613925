import React from "react";
import photo1 from '../../assets/images/1.jpg';
import photo2 from '../../assets/images/2.jpg';
import photo3 from '../../assets/images/3.jpg';
import photo4 from '../../assets/images/4.jpg';
import photo5 from '../../assets/images/5.jpeg';
import photo7 from '../../assets/images/7.jpg';
import moiCiel from '../../assets/images/moi-ciel.jpg';

const TabPictures = () => {
    return(
        <>
            <div class="row">
            <div class="col-lg-4 col-md-12 mb-4 mb-lg-0">
                <img
                src={photo1}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Boat on Calm Water"
                />

                <img
                src={photo4}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Wintry Mountain Landscape"
                />
            </div>

            <div class="col-lg-4 mb-4 mb-lg-0">
                <img
                src={photo3}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Mountains in the Clouds"
                />

                <img
                src={moiCiel}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Boat on Calm Water"
                />
            </div>

            <div class="col-lg-4 mb-4 mb-lg-0">
                <img
                src={photo5}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Waves at Sea"
                />

                <img
                src={photo7}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Yosemite National Park"
                />
            </div>
            </div>
        </>
    )
}

export default TabPictures;