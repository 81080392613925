import React from "react";
import './TabVideo.css';
import video from '../../assets/images/video.png';


const TabVideo = () => {

  const styles = {
    // background: linearGradient(179deg, #ff914d, #ffde59);
  }

    return(
<>
<h3 style={{color: 'white'}}>Videos d'epreuves</h3>


<div class="card-deck">
  <div class="card">
    <img class="card-img-top" src={video} alt="Card image cap"/>
    <div class="card-video-bg card-body">
      <h5 class="card-title white-text">Epreuve n°1</h5>
      <p class="card-text white-text">Effectuer 5 pompes.</p>
      <p class="card-text"><small class="text-muted white-text">Il y a 3 semaines</small></p>
    </div>
  </div>
  <div class="card">
    <img class="card-img-top" src={video} alt="Card image cap"/>
    <div class="card-video-bg card-body">
      <h5 class="card-title white-text">Epreuve n°2</h5>
      <p class="card-text white-text">Faire 10 Squats.</p>
      <p class="card-text"><small class="text-muted white-text">Il y a 2 semaines</small></p>
    </div>
  </div>
  <div class="card">
    <img class="card-img-top" src={video} alt="Card image cap"/>
    <div class="card-video-bg card-body">
      <h5 class="card-title white-text">Epreuve n°3</h5>
      <p class="card-text white-text">Tenir la planche 10 seconds.</p>
      <p class="card-text"><small class="text-muted white-text">Il y a 1 semaines</small></p>
    </div>
  </div>
</div>
  
  
</>
    )
}

export default TabVideo;