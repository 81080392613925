import react from 'react';
import './Menu.css';
import logo from '../../assets/images/logo-flow1.png';
// import {Link, NavLink} from "react-router-dom";

const Menu = () => {
    let cond = true;
    return(
        <>
            <header>
                <div class="header-area" style={{height: '9vw'}}>
                    {/* <div class="container "> */}
                        <div class="row align-items-center">
                            <div class="div-logo col-12">
                                <img class="logo1" style={{width: '9vw'}} src={logo} alt="logo-flow"/>
                            </div>
                        </div>
                    {/* </div> */}
                </div>
            </header>
        </>
    )
    
}

export default Menu;