import {React, useState} from "react";
import './profil.css'
import '../../assets/fontawesome-free-6.1.1-web/css/all.css';
import planche from '../../assets/images/moi-planche2.jpg';
import rise from '../../assets/images/rise.jpg';
import moi from '../../assets/images/moi-ciel2.jpg';
import moi2 from '../../assets/assets-profil/moi-dtr2.jpg';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TabPicture from '../TabPicture/TabPicture';
import TabTrophee from '../TabTrophee/TabTrophee';
import TabActus from '../TabActus/TabActus';
import TabVideo from '../TabVideo/TabVideo';
import logo from '../../assets/images/logo-flow1.png';
import background3 from "../../assets/assets-profil/rectangle.png"
import logoFlow from "../../assets/assets-profil/logo.png"




const Profil = () => {
    // const qrFetch = async()=>{

    //     const response = await fetch('https://qrcode-monkey.p.rapidapi.com/qr/custom', {
    //         //         //     method:'POST',', {
    //         method: 'POST',
    //         headers: {
    //             'content-type': 'application/json',
    //             'X-RapidAPI-Key': '1f27742f78msh1e856d9f6324089p1c81e1jsn7d9905db79e5',
    //             'X-RapidAPI-Host': 'qrcode-monkey.p.rapidapi.com'
    //         },
    //         data: {
    //             data: 'https://www.qrcode-monkey.com',
    //             config: {
    //             body: 'rounded-pointed',
    //             eye: 'frame14',
    //             eyeBall: 'ball16',
    //             erf1: [],
    //             erf2: ['fh'],
    //             erf3: ['fv'],
    //             brf1: [],
    //             brf2: ['fh'],
    //             brf3: ['fv'],
    //             bodyColor: '#5C8B29',
    //             bgColor: '#FFFFFF',
    //             eye1Color: '#3F6B2B',
    //             eye2Color: '#3F6B2B',
    //             eye3Color: '#3F6B2B',
    //             eyeBall1Color: '#60A541',
    //             eyeBall2Color: '#60A541',
    //             eyeBall3Color: '#60A541',
    //             gradientColor1: '#5C8B29',
    //             gradientColor2: '#25492F',
    //             gradientType: 'radial',
    //             gradientOnEyes: false,
    //             logo: ''
    //             },
    //             size: 300,
    //             download: false,
    //             file: 'png'
    //         }
    //     });
    //     const dataObj = await response.json();
    //     console.log(dataObj)


    //     // const axios = require('axios');

    //     // const options = {
    //     // method: 'POST',
    //     // url: 'https://qrcode-monkey.p.rapidapi.com/qr/custom',
    //     // headers: {
    //     //     'content-type': 'application/json',
    //     //     'X-RapidAPI-Key': '1f27742f78msh1e856d9f6324089p1c81e1jsn7d9905db79e5',
    //     //     'X-RapidAPI-Host': 'qrcode-monkey.p.rapidapi.com'
    //     // },
    //     // data: {
    //     //     data: 'https://www.qrcode-monkey.com',
    //     //     config: {
    //     //     body: 'rounded-pointed',
    //     //     eye: 'frame14',
    //     //     eyeBall: 'ball16',
    //     //     erf1: [],
    //     //     erf2: ['fh'],
    //     //     erf3: ['fv'],
    //     //     brf1: [],
    //     //     brf2: ['fh'],
    //     //     brf3: ['fv'],
    //     //     bodyColor: '#5C8B29',
    //     //     bgColor: '#FFFFFF',
    //     //     eye1Color: '#3F6B2B',
    //     //     eye2Color: '#3F6B2B',
    //     //     eye3Color: '#3F6B2B',
    //     //     eyeBall1Color: '#60A541',
    //     //     eyeBall2Color: '#60A541',
    //     //     eyeBall3Color: '#60A541',
    //     //     gradientColor1: '#5C8B29',
    //     //     gradientColor2: '#25492F',
    //     //     gradientType: 'radial',
    //     //     gradientOnEyes: false,
    //     //     logo: ''
    //     //     },
    //     //     size: 300,
    //     //     download: false,
    //     //     file: 'png'
    //     // }
    //     // };
        
    //     // try {
    //     //     const response = await Axios.request(options);
    //     //     console.log(response.data);
    //     // } catch (error) {
    //     //     console.error(error);
    //     // }
    // }




    // const axios = require('axios');

    // const options = {
    //   method: 'POST',
    //   url: 'https://qrcode-monkey.p.rapidapi.com/qr/custom',
    //   headers: {
    //     'content-type': 'application/json',
    //     'X-RapidAPI-Key': 'SIGN-UP-FOR-KEY',
    //     'X-RapidAPI-Host': 'qrcode-monkey.p.rapidapi.com'
    //   },
    //   data: {
    //     data: 'https://www.qrcode-monkey.com',
    //     config: {
    //       body: 'rounded-pointed',
    //       eye: 'frame14',
    //       eyeBall: 'ball16',
    //       erf1: [],
    //       erf2: ['fh'],
    //       erf3: ['fv'],
    //       brf1: [],
    //       brf2: ['fh'],
    //       brf3: ['fv'],
    //       bodyColor: '#5C8B29',
    //       bgColor: '#FFFFFF',
    //       eye1Color: '#3F6B2B',
    //       eye2Color: '#3F6B2B',
    //       eye3Color: '#3F6B2B',
    //       eyeBall1Color: '#60A541',
    //       eyeBall2Color: '#60A541',
    //       eyeBall3Color: '#60A541',
    //       gradientColor1: '#5C8B29',
    //       gradientColor2: '#25492F',
    //       gradientType: 'radial',
    //       gradientOnEyes: false,
    //       logo: ''
    //     },
    //     size: 300,
    //     download: false,
    //     file: 'png'
    //   }
    // };
    
    // try {
    //     const response = await axios.request(options);
    //     console.log(response.data);
    // } catch (error) {
    //     console.error(error);
    // }

    const image = require("../../assets/images/rise.jpg")
    const urlImage = "background: linear-gradient( rgb(0 0 0 / 79%), rgb(0 0 0) ),url(/img/trainings/abdo-h.jpg)";

    const [activTab, setActiveTab] = useState("Actus");

    const handleTab = (name) => {  
        setActiveTab(name);
        // switchTab(activTab);
        console.log(activTab);
    }
    
    const switchTab = (name) => {
        switch (name) {
          case 'Photos':
            return <TabPicture/>;
            break;
          case 'Videos':
            return <TabVideo/>;
            break;
          case 'Trophée':
            return <TabTrophee/>;
            break;
          case 'Actus':
            return <TabActus/>;
            break;
        }
      }

      const styles = {
        divIcons:{
            // display: 'flex',
            // // textAlign:'center',
            // justifyContent: 'space-between',
            paddingTop:'10%'
        },
        imgProfil:{
            width: '24vw',
            borderRadius: '85%',
            border: '2.5vw solid rgb(0, 0, 0)',
            marginTop: '-70%',
            // padding: '35px'
            marginLeft:'-0.5vw',
            position: 'absolute',

        }
      }

    return (

        <div className="container-fluid body-profil">
            <div className="img-cover row" style={{display: 'flex', justifyContent: 'center'}}>
                <div style={{marginLeft:'-11.5vw'}}
                className="col-2 justify-content-center"
                // style={{textAlign: 'center', display:'flex', flexDirection: 'column', backgroundColor: 'black', width: '25%', alignSelf: 'center', alignItems: 'center', alignContent: 'center', justifyContent: 'center', position: 'absolute', borderRadius: '298px', elevation: 0, borderWidth: 14, borderColor: '#FFFFFF' }}
                >
                    {/* <div> */}
                        {/* <img src={require('../../assets/assets-profil/logo.png')} style={{width: '70%', padding:'30px'}} resizeMode="contain"/> */}
                        <img style={styles.imgProfil} src={moi2} alt="user"/>
                    {/* </div> */}
                </div>
            </div>

            <div className="div-button row" style={styles.divIcons}>
                <div className="col-12 d-flex justify-content-between">
                    <div className="" style={{textAlign:'-webkit-center'}}>
                        <div>
                            <div>
                                <img src={require('../../assets/assets-profil/fire.png')} style={{width: '7vw', height:'auto', textAlign: 'center', display:'block', marginLeft:'11vw'}} resizeMode="contain"/>
                            </div> 
                            <div style={{color: '#fff', fontSize:'2vw', width: '18%', height: 'auto', textAlign: 'center', display: 'block', marginLeft:'11vw'}}>
                                100
                            </div>
                        </div>
                    </div>
                    <div className="" style={{textAlign:'-webkit-center'}}>
                        <div>
                            <div>
                                <img src={require('../../assets/assets-profil/team.png')} style={{width: '7vw', height:'auto', textAlign: 'center', display:'block', marginRight:'13vw'}} resizeMode="contain"/>
                            </div> 
                            <div style={{color: '#fff', fontSize:'2vw', width: '18%', height: 'auto', textAlign: 'center', display: 'block', marginRight:'13vw'}}>
                                100
                            </div>
                        </div>
                    </div>
                </div>
                
                {/* <div className="col-6 d-flex justify-content-end"
                //style={{paddingLeft:"10%", justifyContent: 'flex-end', display: 'flex', paddingRight: '6%'}}
                >
                    <span>
                        <img src={require('../../assets/assets-profil/team.png')} style={{width: '18%', height:'auto'}} resizeMode="contain"/>
                    </span>
                    <span style={{display: 'flex', justifyContent: 'center', color: '#fff', fontSize:12, width: '18%', height: 'auto', textAlign: 'center', display: 'block', paddingRight: '6%'}}>
                        1000
                    </span>
                </div>                                 */}
            </div>

            <div className="divBtn row" style={{ display:'flex', justifyContent:'center', marginTop:'15%'}} >
                {/* <img src={require('../../assets/assets-profil/rectangle.png')}/> */}
                <div className="btnGradient" style={{marginRight:'20%', width:'20%', padding:'2%', borderRadius:'7px', textAlign:'center' , fontWeight:'bold', fontSize:'3vw'}}>Suivre</div>
                <div  className="btnGradient" style={{width:'20%', padding:'2%', borderRadius:'7px', textAlign:'center', fontWeight:'bold', fontSize:'3vw'}}>Message</div>
            </div>

            <div className='divRectangle row' style={{display:"flex", justifyContent: 'center', alignItems: 'center', marginTop:'7%'}}>
                <div className="col-12 d-flex justify-content-center">
                    <img style={{ width: '70%' }} resizeMode='contain' src={require('../../assets/assets-profil/border-yellow.png')} />
                    <span className="text-rectangle">« Votre corps peut presque tout faire »</span>
                    <span style={{ paddingTop:'3%' }} className="text-rectangle">« c'est votre tête qui a besoin d'en être convaincue »</span>
                </div>
            </div>

            <section className='row' style={{paddingTop: '5%'}}>
                <div className='col-12' style={{paddingTop: '8px', paddingBottom: '8px', marginBottom: '30px', display: 'flex', justifyContent: 'space-around'}}>
                    <img src={require("../../assets/assets-profil/losange-actus.png")} style={{maxWidth: '20%'}} className="" onClick={() => handleTab('Actus')}/>
                    <img src={require("../../assets/assets-profil/losange-medias.png")} style={{maxWidth: '20%'}} className="" onClick={() => handleTab('Photos')}/>
                    <img src={require("../../assets/assets-profil/losange-coffre.png")} style={{maxWidth: '20%'}} className="" onClick={() => handleTab('Trophée')}/>
                    <img src={require("../../assets/assets-profil/losange-guilde.png")} style={{maxWidth: '20%'}} className="" onClick={() => handleTab('Videos')}/>
                    {/* <div style={{borderRight: 'solid'}} className="tabs col-3"><span onClick={() => handleTab('Photos')}>Photos</span></div>
                    <div style={{borderRight: 'solid'}} className="tabs col-3"><span onClick={() => handleTab('Videos')}>Videos</span></div>
                    <div className="tabs col-3"><span onClick={() => handleTab('Trophée')}>Trophées</span></div> */}
                </div>
            </section>

            <section>
                {switchTab(activTab)}
            </section>

            <footer style={{backgroundColor:'black'}} class="row py-3 border-top">
                <div class="col-6 d-flex align-items-center">
                    <img style={{backgroundColor:"black", width:"50px", marginRight: '10px'}} src={logo} alt="" />
                    <span>© 2023 Flow    Baou.sogoyou@gmail.com</span>
                </div>
                <div className="col-6">
                    <ul class="nav justify-content-end list-unstyled d-flex">
                    <li class="ms-3"><i class="fa-brands fa-twitter"></i></li>
                    <li style={{marginLeft:"5px"}} class="ms-3"><i class="fa-brands fa-instagram-square"></i></li>
                    <li style={{marginLeft:"5px"}} class="ms-3"><i class="fa-brands fa-facebook"></i></li>
                </ul>
                </div>
            </footer>
            {/* <div style={{ display:'flex', justifyContent:'center'}} className="">
                <div ><span style={{ backgroundImage: `url(${background3})`, width: '30%', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}  >gv ,bv ,hg ,hg ;h ;b</span></div>
                <div style={{ backgroundImage: `url(${background3})`, width: '30%', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}>gv ,bv ,hg ,hg ;h ;b</div>
            </div> */}

        </div>


        // <div className="body-profil">
        //     <div className="img-cover" style={{display: 'flex', justifyContent: 'center'}}>
        //         {/* <div className="">
        //             <div className="img-header">
        //                 <img class="img-profil" src={logoFlow} alt="user"/>
        //             </div>
        //         </div> */}
        //     {/* <div style={{paddingTop:20, backgroundColor: 'red', zIndex: 9, justifyContent: 'center',display:'flex', flexDirection: 'column'}}>

        //     </div> */}
        //                   <div style={{textAlign: 'center', display:'flex', flexDirection: 'column', backgroundColor: 'black', width: '25%', height: '90%', alignSelf: 'center', alignItems: 'center', alignContent: 'center', justifyContent: 'center', position: 'absolute', borderRadius: '298px', elevation: 0, borderWidth: 14, borderColor: '#FFFFFF' }}>
        //         <div>
        //             <img src={require('../../assets/assets-profil/logo.png')} style={{width: '70%', padding:'30px'}} resizeMode="contain"/>
        //         </div>
        //         {/* <div>
        //             <span style={{color: '#fff', fontSize: 13, fontWeight:'bold'}}>Flow</span>
        //         </div> */}
        //       </div>
        //     </div>
        //     <div className="div-button" style={styles.divIcons}>
        //         <div style={{paddingLeft:"10%"}}>
        //             <span><img src={require('../../assets/assets-profil/fire.png')} style={{width: '18%', height:'auto', textAlign: 'center', display:'block', paddingLeft:'6%'}} resizeMode="contain"/></span> 
        //             <span style={{color: '#fff', fontSize:12, width: '18%', height: 'auto', textAlign: 'center', display: 'block', paddingLeft: '6%'}}>
        //                 100
        //             </span>
        //         </div>
        //         <div style={{paddingLeft:"10%", justifyContent: 'flex-end', display: 'flex', paddingRight: '6%'}}>
        //             <span style={{display: 'flex', justifyContent: 'center'}}>
        //                 <img src={require('../../assets/assets-profil/team.png')} style={{width: '18%', height:'auto'}} resizeMode="contain"/>
        //             </span>
        //             <span style={{display: 'flex', justifyContent: 'center', color: '#fff', fontSize:12, width: '18%', height: 'auto', textAlign: 'center', display: 'block', paddingRight: '6%'}}>
        //                 1000
        //             </span>
        //         </div>                                
        //     </div>
        //     <div className="divBtn" style={{ display:'flex', justifyContent:'center', marginTop:'15%'}} >
        //         {/* <img src={require('../../assets/assets-profil/rectangle.png')}/> */}
        //         <div className="btnGradient" style={{marginRight:'20%', width:'20%', padding:'2%', borderRadius:'7px', textAlign:'center' , fontWeight:'bold', fontSize:'x-large'}}>Suivre</div>
        //         <div className="btnGradient" style={{width:'20%', padding:'2%', borderRadius:'7px', textAlign:'center', fontWeight:'bold', fontSize:'x-large'}}>Message</div>
        //     </div>
        //     <div className='divRectangle' style={{display:"flex", justifyContent: 'center', alignItems: 'center', marginTop:'7%'}}>
        //         <img style={{ width: '70%' }} resizeMode='contain' src={require('../../assets/assets-profil/border-yellow.png')} />
        //     </div>

        //     <section style={{paddingTop: '5%'}}>
        //         <div style={{paddingTop: '8px', paddingBottom: '8px', marginBottom: '30px', display: 'flex', justifyContent: 'space-around'}} className="">
        //             <img src={require("../../assets/assets-profil/losange-actus.png")} style={{maxWidth: '20%'}} className="" onClick={() => handleTab('Actus')}/>
        //             <img src={require("../../assets/assets-profil/losange-medias.png")} style={{maxWidth: '20%'}} className="" onClick={() => handleTab('Photos')}/>
        //             <img src={require("../../assets/assets-profil/losange-coffre.png")} style={{maxWidth: '20%'}} className="" onClick={() => handleTab('Videos')}/>
        //             <img src={require("../../assets/assets-profil/losange-guilde.png")} style={{maxWidth: '20%'}} className="" onClick={() => handleTab('Trophée')}/>
        //             {/* <div style={{borderRight: 'solid'}} className="tabs col-3"><span onClick={() => handleTab('Photos')}>Photos</span></div>
        //             <div style={{borderRight: 'solid'}} className="tabs col-3"><span onClick={() => handleTab('Videos')}>Videos</span></div>
        //             <div className="tabs col-3"><span onClick={() => handleTab('Trophée')}>Trophées</span></div> */}
        //         </div>
        //     </section>
        //     <section>
        //         {switchTab(activTab)}
        //     </section>
        //     <footer class="row py-3 my-4 border-top">
        //         <div class="col-6 d-flex align-items-center">
        //             <img style={{backgroundColor:"black", width:"50px", marginRight: '10px'}} src={logo} alt="" />
        //             <span>© 2023 Flow    Baou.sogoyou@gmail.com</span>
        //         </div>
        //         <div className="col-6">
        //             <ul class="nav justify-content-end list-unstyled d-flex">
        //             <li class="ms-3"><i class="fa-brands fa-twitter"></i></li>
        //             <li style={{marginLeft:"5px"}} class="ms-3"><i class="fa-brands fa-instagram-square"></i></li>
        //             <li style={{marginLeft:"5px"}} class="ms-3"><i class="fa-brands fa-facebook"></i></li>
        //         </ul>
        //         </div>
        //     </footer>
        //     {/* <div style={{ display:'flex', justifyContent:'center'}} className="">
        //         <div ><span style={{ backgroundImage: `url(${background3})`, width: '30%', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}  >gv ,bv ,hg ,hg ;h ;b</span></div>
        //         <div style={{ backgroundImage: `url(${background3})`, width: '30%', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}>gv ,bv ,hg ,hg ;h ;b</div>
        //     </div> */}

        // </div>
        // <div style={{paddingTop: '82px'}}>
        //     <div class="">
        //         <div style={{backgroundColor:'black'}} class="card"> 
        //             <div className="img-cover row"></div>
        //             <div className="row">
                        // <div className="col-12">
                        //     <div className="col-12 img-header">
                        //         <img class="img-profil" src={moi2} alt="user"/>
                        //     </div>
                        // </div>
        //             </div>
        //             <div class="card-body little-profile text-center">
        //                 <div className="row">
        //                     <div className="col-3" style={{marginTop:"-10%"}}>
        //                         <img src={require('../../assets/assets-profil/fire.png')} style={{width: 50, height:50}} resizeMode="contain"/>                                
        //                         <p style={{fontSize: '85%', fontWeight:'bold'}}>Flow</p>
        //                         <p style={{fontSize: '85%'}}>105</p>
        //                     </div>
        //                     <div class="col-6">
        //                         {/* <img style={{borderStyle: 'solid', borderWidth: '10px', borderColor: 'white'}} src={moi} alt="user"/> */}
        //                         <h3 style={{fontSize: '130%', fontWeight:'bold', color: 'black'}}>Rang 15</h3>
        //                         <p>Classe Agile &amp; Force</p> 
        //                         <div className="row"></div>
        //                     </div>
        //                     <div className="col-3" style={{marginTop:"-10%"}}>
        //                         <div>
        //                             <img src={require('../../assets/assets-profil/team.png')} style={{width: 50, height:50}} resizeMode="contain"/>
        //                             <p style={{fontSize: '85%', fontWeight:'bold'}}>Follower</p>
        //                             <p style={{fontSize: '85%'}}>3928</p>
        //                         </div>
        //                     </div>
        //                 </div>

                        // <div style={{ display:'flex', paddingBottom:'100px'}} className="">
                        //     <div style={{ img: `url(${background3})`}}>gv ,bv ,hg ,hg ;h ;b</div>
                        //     <div style={{ backgroundImage: `url(${background3})`}}>gv ,bv ,hg ,hg ;h ;b</div>
                        // </div>


                        // <div style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                        //     <img style={{ width: 500 }} resizeMode='contain' src={require('../../assets/assets-profil/border-yellow.png')} />
                        // </div>

                        // <section style={{paddingTop: '5%'}}>
                        //     <div style={{backgroundColor: '#2472d4', paddingTop: '8px', paddingBottom: '8px', marginBottom: '30px'}} className="row">
                        //         <div style={{borderRight: 'solid'}} className="tabs col-3"><span onClick={() => handleTab('Actus')}>Actus</span></div>
                        //         <div style={{borderRight: 'solid'}} className="tabs col-3"><span onClick={() => handleTab('Photos')}>Photos</span></div>
                        //         <div style={{borderRight: 'solid'}} className="tabs col-3"><span onClick={() => handleTab('Videos')}>Videos</span></div>
                        //         <div className="tabs col-3"><span onClick={() => handleTab('Trophée')}>Trophées</span></div>
                        //     </div>
                        // </section>
                        // <section>
                        //     {switchTab(activTab)}
                        // </section>
                        // <footer class="row py-3 my-4 border-top">
                        //     <div class="col-6 d-flex align-items-center">
                        //         <img style={{backgroundColor:"black", width:"50px", marginRight: '10px'}} src={logo} alt="" />
                        //         <span>© 2023 Flow    Baou.sogoyou@gmail.com</span>
                        //     </div>
                        //     <div className="col-6">
                        //         <ul class="nav justify-content-end list-unstyled d-flex">
                        //         <li class="ms-3"><i class="fa-brands fa-twitter"></i></li>
                        //         <li style={{marginLeft:"5px"}} class="ms-3"><i class="fa-brands fa-instagram-square"></i></li>
                        //         <li style={{marginLeft:"5px"}} class="ms-3"><i class="fa-brands fa-facebook"></i></li>
                        //     </ul>
                        //     </div>
                        // </footer>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    );
}

export default Profil;