import React from "react";
import './TabTrophee.css';

const TabTrophee = () => {
    return(
        <>
            {/* <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" /> */}

            <div class="row row-cols-1 row-cols-md-2 row-cols-xl-4">
                <div class="col">
                    <div class="card-trophee-bg card radius-10 border-start border-0 border-3 border-info">
                        <div class="card-body">
                            <div class="d-flex align-items-center">
                                <div>
                                    <p class="mb-0 text-secondary">Trop de Flow</p>
                                    {/* <h4 class="my-1 text-info">105</h4> */}
                                    <p class="mb-0 font-13">Atteindre 100 flow</p>
                                </div>
                                <div class="widgets-icons-2 rounded-circle bg-gradient-scooter text-white ms-auto"><i class="fa-solid fa-feather-pointed"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card-trophee-bg card radius-10 border-start border-0 border-3 border-danger">
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div>
                                <p class="mb-0 text-secondary">C'est partie</p>
                                {/* <h4 class="my-1 text-danger">$84,245</h4> */}
                                <p class="mb-0 font-13">reussir la 1er epreuve</p>
                            </div>
                            <div class="widgets-icons-2 rounded-circle bg-gradient-bloody text-white ms-auto"><i class="fa-solid fa-medal"></i>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card-trophee-bg card radius-10 border-start border-0 border-3 border-success">
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div>
                                <p class="mb-0 text-secondary">Gukins</p>
                                {/* <h4 class="my-1 text-success">34.6%</h4> */}
                                <p class="mb-0 font-13">Achever la saison 1</p>
                            </div>
                            <div class="widgets-icons-2 rounded-circle bg-gradient-ohhappiness text-white ms-auto"><i class="fa-solid fa-trophy"></i>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card-trophee-bg card radius-10 border-start border-0 border-3 border-warning">
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div>
                                <p class="mb-0 text-secondary">J'ai la cotte</p>
                                {/* <h4 class="my-1 text-warning">8.4K</h4> */}
                                <p class="mb-0 font-13">+ de 100 follower</p>
                            </div>
                            <div class="widgets-icons-2 rounded-circle bg-gradient-blooker text-white ms-auto"><i class="fa fa-users"></i>
                            </div>
                        </div>
                    </div>
                    </div>
                </div> 
                </div>
        </>
    )
}

export default TabTrophee;