import logo from './logo.svg';
import './App.css';
import Profil from './components/Profil/Profil';
import Menu from './components/Menu/Menu';


function App() {


    
  return (
    <>
      <Menu/>
      <Profil/>
    </>

  );
}

export default App;


