import React from "react";
import './TabActus.css';
import moi from '../../assets/images/moi-ciel2.jpg';
// import moi2 from '../../assets/images/2.jpg';
import moi2 from '../../assets/assets-profil/moi-dtr2.jpg';

import photoActu from '../../assets/images/7.jpg';


const TabActus = () => {
    return(
        <>
            <div class="d-flex justify-content-center row">
                <div class="col-md-8">
                    <div style={{backgroundColor:'#FFC100'}} class="feed p-2">
                        <div class="back-color-card d-flex flex-row justify-content-between align-items-center p-2  border">
                            <div class="feed-text px-2">
                                <h2 style={{fontWeight: 'bold', color:'white', fontSize:'1rem'}} >Chaque réussite commence avec la volonté d'essayer !</h2>
                            </div>
                            <div class="feed-icon px-2"><i class="fa fa-long-arrow-up text-white-50"></i></div>
                        </div>
                        <div class=" back-color-card border mt-2">
                            <div>
                                <div class="back-color-card d-flex flex-row justify-content-between align-items-center p-2 border-bottom">
                                    <div class="back-color-card d-flex flex-row align-items-center feed-text px-2"><img class="rounded-circle" src={moi2} width="45"/>
                                        <div class="d-flex flex-column flex-wrap ml-2"><span class="font-weight-bold" style={{color:'white'}}>Baou Flow</span><span class="text-white-50 time" style={{color:'white'}}>Il y a 40 minutes</span></div>
                                    </div>
                                    <div class="feed-icon px-2"><i class="fa fa-ellipsis-v text-white-50"></i></div>
                                </div>
                            </div>
                            <div class="p-2 px-3"><span style={{color:'white'}}>Qui est dispo pour une session d'entrainement Foot freestyle à la defense demain ? Je suis debutant, je viens de commencer la classe Agilité.</span></div>
                            <div class="d-flex justify-content-end socials p-2 py-3"><i class="fa fa-thumbs-up"></i><i class="fa fa-comments-o"></i><i class="fa fa-share"></i></div>
                        </div>
                        <div class="back-color-card border mt-2">
                            <div>
                                <div class="back-color-card d-flex flex-row justify-content-between align-items-center p-2 border-bottom">
                                    <div class="back-color-card d-flex flex-row align-items-center feed-text px-2"><img class="rounded-circle" src={moi2} width="45"/>
                                        <div class="d-flex flex-column flex-wrap ml-2"><span class="font-weight-bold" style={{color:'white'}}>Baou Flow</span><span class="text-white-50 time" style={{color:'white'}}>Il y a 40 minutes</span></div>
                                    </div>
                                    <div class="feed-icon px-2"><i class="fa fa-ellipsis-v text-white-50"></i></div>
                                </div>
                            </div>
                            <div class="feed-image p-2 px-3"><img class="img-fluid img-responsive" src={photoActu}/></div>
                            <div class="d-flex justify-content-end socials p-2 py-3"><i class="fa fa-thumbs-up"></i><i class="fa fa-comments-o"></i><i class="fa fa-share"></i></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TabActus;